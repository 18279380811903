import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState, useRef, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import Header from "./components/Header";
import Login from './components/Login';
import Reservation from './components/Reservations';
import Cookies from 'universal-cookie';

function App() {
  const cookies = new Cookies();
  const googlebuttonref = useRef();
  const [session, setSession] = useState();
  // Solo se hace la verificacion del usuario al iniciar sesion
  // El seguimiento de inicio de sesión se hace con cookies
  useEffect(() => {
    (cookies.get('email_verified') === "true") ? setSession(true) : setSession(false);
    window.google.accounts.id.initialize({
      client_id: "128491853917-p2p3363ssfmqvroc01siq5v0irptvg5u.apps.googleusercontent.com",
      callback: onGoogleSignIn,
      auto_select: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const onGoogleSignIn = (user) => {
    let userGoogle = jwt_decode(user.credential);
    if(userGoogle.email_verified){
      console.log(userGoogle);
      cookies.set('given_name', userGoogle.name);
      cookies.set('email', userGoogle.email);
      cookies.set('picture', userGoogle.picture);
      cookies.set('email_verified', userGoogle.email_verified);
      if(userGoogle.email_verified === true){
        setSession(true);
      }
    }
  }

  useEffect(() => {
    if(session === false){
      cookies.remove('given_name');
      cookies.remove('email');
      cookies.remove('picture');
      cookies.remove('email_verified');
      window.localStorage.removeItem('token');
      window.google.accounts.id.renderButton(googlebuttonref.current, {
        size: "medium",
        theme: "outline",
      });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])
 
  return (
    <div className="containerApp">
      { (session === true) 
        ? 
        <div>
          <Header session={session}/>
          <Reservation session={session} setSession={setSession} userPicture={cookies.get("picture")}/>
        </div>
        :  
        <div>
          <Header session={session}/>
          <Login googlebuttonref={googlebuttonref}/>
        </div>
      }
    </div>
  );
}
export default App;
