function Login({googlebuttonref}) {
  return (
    <div>
      <div className="Login">
        <div className="btnGoogle" ref={googlebuttonref}></div>
      </div>
    </div>
  );
}

export default Login;
