function App({session}) {


  return (
    <header>
          <nav className={session !== false ? "navbar navbarVariant2" : "navbar"}>
            <div className="container-fluid px-5 py-3">
                <a className="navbar-brand brandCol" href="/">
                    <img src="/images/mmk.svg" alt="" width="130" height="auto" />
                </a>
                <h3 className="textCol">Reservación de Salas de MMKG</h3>
            </div>
        </nav>
    </header>
  );
}

export default App;

